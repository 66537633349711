import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Mobile from './img/intro.png';
import Logo from './img/logoescola.png';
import DataSaude from './saude.json';
import DataIndustria from './industria.json';
import DataGestao from './gestao.json';
import api from './services';

import './main.css';

function App() {
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState([]);

  async function handleSubmit(e) {
    e.preventDefault();
    await api.post('enquete', { id: selected });
    toast('🦄 Sua resposta foi enviado com sucesso!!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    setTimeout(() => { window.location.href = 'https://santarita.avaresidencia.com.br/'; }, 3000);
  }
  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChange(e, panel) {
    const expandedIndex = expanded.indexOf(panel);
    let newExpanded = [];
    if (expandedIndex === -1) {
      newExpanded = newExpanded.concat(expanded, panel);
    } else if (expandedIndex === 0) {
      newExpanded = newExpanded.concat(expanded.slice(1));
    } else if (expandedIndex === expanded.length - 1) {
      newExpanded = newExpanded.concat(expanded.slice(0, -1));
    } else if (expandedIndex > 0) {
      newExpanded = newExpanded.concat(
        expanded.slice(0, expandedIndex),
        expanded.slice(expandedIndex + 1),
      );
    }

    setExpanded(newExpanded);
  }
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isExpanded = (name) => expanded.indexOf(name) !== -1;

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
      <header id="home" className="hero-area-2">
        <div className="overlay" />
        <nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
          <div className="container">
            <img src={Logo} alt="" />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="lni-menu" />
            </button>
          </div>
        </nav>
        <div className="container">
          <div className="row space-100">
            <div className="col-lg-7 col-md-12 col-xs-12">
              <div className="contents">
                <h2 className="head-title">
                Projeto de Vida e Itinerários Formativos
                </h2>
                <iframe title="youtube" width="100%" height="315" src="https://www.youtube.com/embed/b-6MLlAiwIU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-xs-12">
              <div className="intro-img">
                <img src={Mobile} alt="" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <section id="eixo-saude" className="section">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">AMBIENTE E SAÚDE</h2>
            <p>
              O eixo tecnológico de AMBIENTE E SAÚDE compreende tecnologias
              associadas à melhoria da qualidade de vida, à preservação e
              utilização da natureza, desenvolvimento e inovação do aparato
              tecnológico de suporte e atenção à saúde. Abrangem ações de
              proteção e preservação dos seres vivos e dos recursos ambientais,
              da segurança de pessoas e comunidades, do controle e avaliação de
              risco, programas de educação ambiental.
            </p>
            <button
              type="button"
              onClick={(e) => handleChange(e, 'painel1')}
              className="btn btn-border mt-30"
            >
              Ver todas as disciplinas de AMBIENTE E SAÚDE
            </button>
          </div>
          <div
            className="row"
            expanded={isExpanded('painel1') ? 'true' : 'false'}
          >
            {DataSaude.map((saude) => {
              const isItemSelected = isSelected(saude.id);
              const labelId = `enhanced-table-checkbox-${saude.id}`;
              return (
                <div className="col-lg-3 col-md-6 col-xs-12" key={saude.id}>
                  <button
                    type="button"
                    ge={isItemSelected ? 'true' : 'false'}
                    onClick={(event) => handleClick(event, saude.id)}
                  >
                    <div
                      className="single-team wow fadeInUp"
                      ge={isItemSelected ? 'true' : 'false'}
                      id={labelId}
                      data-wow-delay="0.2s"
                    >
                      <div className="team-details">
                        <div className="team-inner">
                          <h4 className="team-title">{saude.name}</h4>
                          <p>{`carga horária: ${saude.workload}`}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section id="eixo-industria" className="section-second">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">INDÚSTRIA</h2>
            <p>
              O eixo tecnológico de INDÚSTRIA compreende tecnologias associadas
              a infraestrutura e aos processos mecânicos, elétricos e
              eletroeletrônicos, em atividades produtivas. Abrange proposição,
              instalação, operação, controle, intervenção, manutenção, avaliação
              e otimização de múltiplas variáveis em processos, contínuos ou
              discretos.
            </p>
            <button
              type="button"
              onClick={(e) => handleChange(e, 'painel2')}
              className="btn btn-border mt-30"
            >
              Ver todas as disciplinas do Eixo Indústria
            </button>
          </div>
          <div
            className="row"
            expanded={isExpanded('painel2') ? 'true' : 'false'}
          >
            {DataIndustria.map((industria) => {
              const isItemSelected = isSelected(industria.id);
              const labelId = `enhanced-table-checkbox-${industria.id}`;
              return (
                <div className="col-lg-3 col-md-6 col-xs-12" key={industria.id}>
                  <button
                    type="button"
                    ge={isItemSelected ? 'true' : 'false'}
                    onClick={(event) => handleClick(event, industria.id)}
                  >
                    <div
                      className="single-team wow fadeInUp"
                      ge={isItemSelected ? 'true' : 'false'}
                      id={labelId}
                      data-wow-delay="0.2s"
                    >
                      <div className="team-details">
                        <div className="team-inner">
                          <h4 className="team-title">{industria.name}</h4>
                          <p>{`carga horária: ${industria.workload}`}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section id="eixo-gestao" className="section-second">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">GESTÃO E NEGÓCIOS</h2>
            <p>
              O eixo tecnológico de GESTÃO E NEGÓCIOS compreende tecnologias
              associadas a instrumentos, técnicas, estratégias e mecanismos de
              gestão. Abrange planejamento, avaliação e gestão de pessoas e de
              processos referentes a negócios e serviços presentes em
              organizações e instituições públicas ou privadas, de todos os
              portes e ramos de atuação; busca da qualidade, produtividade e
              competitividade; utilização de tecnologias organizacionais;
              comercialização de produtos; e estratégias de marketing, logística
              e finanças.
            </p>
            <button
              type="button"
              onClick={(e) => handleChange(e, 'painel3')}
              className="btn btn-border mt-30"
            >
              Ver todas as disciplinas do Eixo GESTÃO E NEGÓCIOS
            </button>
          </div>
          <div
            className="row"
            expanded={isExpanded('painel3') ? 'true' : 'false'}
          >
            {DataGestao.map((gestao) => {
              const isItemSelected = isSelected(gestao.id);
              const labelId = `enhanced-table-checkbox-${gestao.id}`;
              return (
                <div className="col-lg-3 col-md-6 col-xs-12" key={gestao.id}>
                  <button
                    type="button"
                    ge={isItemSelected ? 'true' : 'false'}
                    onClick={(event) => handleClick(event, gestao.id)}
                  >
                    <div
                      className="single-team wow fadeInUp"
                      ge={isItemSelected ? 'true' : 'false'}
                      id={labelId}
                      data-wow-delay="0.2s"
                    >
                      <div className="team-details">
                        <div className="team-inner">
                          <h4 className="team-title">{gestao.name}</h4>
                          <p>{`carga horária: ${gestao.workload}`}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <button
        type="button"
        showbtn={selected.length >= 1 ? 'true' : 'false'}
        onClick={(e) => handleSubmit(e)}
        className="btn btn-float btn-border btn-subtitle"
      >
        Enviar sua resposta
      </button>
    </>
  );
}

export default App;
